/**
 * @desc The 'useBreakpoint()' hook is used to get the current 
 *       screen breakpoint based on the TailwindCSS config.
 *
 * @usage
 *    import { useBreakpoint } from "@/hooks/useBreakpoint";
 *
 *    const { isAboveSm, isBelowSm, sm } = useBreakpoint("sm");
 *
 *    const { isAboveMd } = useBreakpoint("md");
 *    const { isAboveLg } = useBreakpoint("lg");
 *    const { isAbove2Xl } = useBreakpoint("2xl");
 *
 * @see https://stackoverflow.com/a/76630444/6543935
 * @requirements npm install react-responsive
 */
import { useMediaQuery } from "react-responsive";
import resolveConfig from "tailwindcss/resolveConfig";
import { type Config, type ScreensConfig } from "tailwindcss/types/config";

import tailwindConfig from "../../tailwind.config"; // Your tailwind config

const fullConfig = resolveConfig(tailwindConfig as unknown as Config);

type CustomScreensConfig = {
    [K in keyof typeof fullConfig.theme.screens | 'notmobile']?: string;
  };
  
// Default breakpoints, ensuring all necessary keys are included
const defaultBreakpoints: Record<string, string> = {
    xs: "480px",
    sm: "640px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    '2xl': "1536px", // Ensure this matches Tailwind's default if you use it
    notmobile: "900px",
};


const breakpoints: CustomScreensConfig = {
    ...defaultBreakpoints,
    ...fullConfig?.theme?.screens,
};


export default function useBreakpoint<K extends string>(breakpointKey: K) {
    const breakpointValue = breakpoints[breakpointKey as keyof typeof breakpoints];
    const bool = useMediaQuery({
        query: `(max-width: ${breakpointValue})`,
    });
    // const capitalizedKey = breakpointKey[0].toUpperCase() + breakpointKey.substring(1);
    const capitalizedKey = breakpointKey[0] ? breakpointKey[0].toUpperCase() + breakpointKey.substring(1) : undefined;


    type KeyAbove = `isAbove${Capitalize<K>}`;
    type KeyBelow = `isBelow${Capitalize<K>}`;

    return {
        [breakpointKey]: Number(String(breakpointValue).replace(/[^0-9]/g, "")),
        [`isAbove${capitalizedKey}`]: !bool,
        [`isBelow${capitalizedKey}`]: bool,
    } as Record<K, number> & Record<KeyAbove | KeyBelow, boolean>;
}

export function useIsMobile() {
   
    const notMobileBreakpoint = breakpoints.notmobile

    const isMobile = useMediaQuery({
      query: `(max-width: ${notMobileBreakpoint})`,
    });
  
    return isMobile;
  }



