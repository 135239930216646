"use client";

import { useSearchParams } from "next/navigation";
import { labels, locales, usePathname, useRouter, type Locale } from "@/navigation";
import { useLocale, useTranslations } from "next-intl";
import { Button, type ButtonProps } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

type LocalizationMainSwitcherProps = ButtonProps & {
  iconClassName?: string;
  className?: string;
};

export default function LocalizationMainSwitcher({
  iconClassName = "mr-2",
  className,
  ...props
}: LocalizationMainSwitcherProps) {
  const t = useTranslations("LanguageSwitch");
  const client = true;
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const locale = useLocale();

  if (!client)
    return (
      <Button
        disabled
        aria-label="Language Switcher"
        className="border rounded-md"
        variant="ghost"
        {...props}
      >
        <span className="hidden sm:block">
          <LocaleFlagIcon locale={locale} />
          {labels[locale as keyof typeof labels]}
        </span>
        <span className="block sm:hidden -mr-2">
          <LocaleFlagIcon locale={locale} />
        </span>
      </Button>
    );

  const handleClick = (newLocale: Locale) => {
    router.replace(pathname + "?" + searchParams.toString(), {
      locale: newLocale,
    });
  };

  return (
    <div>
      <DropdownMenu>
        <DropdownMenuTrigger asChild className="outline-none">
          <Button variant="outline" aria-label="Language">
            <span className="hidden md:block">
              <span className="flex">
                <LocaleFlagIcon locale={locale} />
                {labels[locale as keyof typeof labels]}
              </span>
            </span>
            <span className="block md:hidden -mr-2">
              <LocaleFlagIcon locale={locale} />
            </span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuLabel className="font-heading">
            {t("choose-language")}
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuRadioGroup
            value={locale}
            onValueChange={(val) => handleClick(val as Locale)}
          >
            {locales.map((locale) => (
              <DropdownMenuRadioItem
                key={locale}
                value={locale}
                className="flex"
              >
                <LocaleFlagIcon locale={locale} />
                {labels[locale as keyof typeof labels]}
              </DropdownMenuRadioItem>
            ))}
          </DropdownMenuRadioGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}

type LocaleFlagIconProps = { locale: string };

function LocaleFlagIcon({ locale }: LocaleFlagIconProps) {
  const baseLocale = locale.split("-")[0];
  if (baseLocale === "en") {
    return <span aria-hidden="true" className="mr-2 fi fi-gb"></span>;
  } else if (baseLocale === "uk") {
    return <span aria-hidden="true" className="mr-2 fi fi-ua"></span>;
  } else if (baseLocale === "pl") {
    return (
      <span
        aria-hidden="true"
        className="mr-2 border border-zinc-200 border-b-0 dark:border-none fi fi-pl"
      ></span>
    );
  } else if (baseLocale === "hi") {
    return <span aria-hidden="true" className="mr-2 fi fi-in"></span>;
  }
  return (
    <span aria-hidden="true" className={`mr-2 fi fi-${baseLocale}`}></span>
  );
}