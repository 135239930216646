import { type Config } from "tailwindcss";
import { fontFamily } from "tailwindcss/defaultTheme";

// const defaultTheme = require("tailwindcss/defaultTheme")
import defaultTheme from "tailwindcss/defaultTheme";

export default {
  darkMode: ["class"],
  // content: ["./src/**/*.{js,ts,jsx,tsx,mdx}"],
  content: [
    // "./node_modules/flowbite-react/lib/esm/**/*.js",
    // "./node_modules/flowbite/**/*.js",
    "./src/**/*.{js,ts,jsx,tsx,mdx}",
    "./public/**/*.html",
    './node_modules/pliny/**/*.js',
  ],
  theme: {
    container: {
      center: true,
      padding: "1rem",
      screens: {
        "2xl": "1400px",
      },

      
      // screens: {
      //   xs: "480px",
      //   xxs: "380px",
      //   "sm" : "640px",
      //   "md" : "768px",
      //   "notmobile" : "900px",
      //   "lg" : "1024px",
      //   "xl" : "1280px",
      //   "2xl" : "1536px",
      // },
    },
    screens: {
      xs: "480px",
      notmobile: "900px",
      mobile: { max: "899px" },
      ...defaultTheme.screens,
    },
    extend: {
      letterSpacing: {
        extensive: "0.2em",
      },
      screens: {
        // xs: "480px",
        // xxs: "380px",
        "notmobile" : "900px",

        // "sm" : "640px",
        // "md" : "768px",
        // "notmobile" : "900px",
        // "lg" : "1024px",
        // "xl" : "1280px",
        // "2xl" : "1536px",
      },
      flex: {
        full: "0 0 100%",
      },
      boxShadow: {
        'card-md': '0px 0px 2px rgba(128, 138, 157, 0.12), 0px 0px 24px rgba(128, 138, 157, 0.14)', 
        'card-sm': '0px 4px 24px rgba(88, 102, 126, 0.08), 0px 1px 2px rgba(88, 102, 126, 0.12)',
        'card-dual': '0px 0px 2px rgba(128, 138, 157, 0.12), 0px 0px 24px rgba(128, 138, 157, 0.14), 0px 4px 24px rgba(88, 102, 126, 0.08), 0px 1px 2px rgba(88, 102, 126, 0.12)',
      },
      order: {
        '13': '13',
        '14': '14',
        '15': '15',
      },
      colors: {
        border: "hsl(var(--border))",
        input: "hsl(var(--input))",
        ring: "hsl(var(--ring))",
        background: "hsl(var(--background))",
        foreground: "hsl(var(--foreground))",
        // contrast: "hsl(var(--contrast))",
        contrast: {
          DEFAULT: "hsl(var(--contrast))",
          foreground: "hsl(var(--contrast-foreground))",
        },
        // gray: {
        //   DEFAULT: "hsl(var(--gray))",
        //   100: "hsl(var(--gray-100))",
        //   200: "hsl(var(--gray-200))",
        //   300: "hsl(var(--gray-300))",
        //   400: "hsl(var(--gray-400))",
        //   500: "hsl(var(--gray-500))",
        //   600: "hsl(var(--gray-600))",
        //   700: "hsl(var(--gray-700))",
        //   800: "hsl(var(--gray-800))",
        //   900: "hsl(var(--gray-900))",
        // },

        slate: {
          DEFAULT: "hsl(var(--slate))",
          50: "hsl(var(--slate-50))",
          100: "hsl(var(--slate-100))",
          200: "hsl(var(--slate-200))",
          300: "hsl(var(--slate-300))",
          400: "hsl(var(--slate-400))",
          500: "hsl(var(--slate-500))",
          600: "hsl(var(--slate-600))",
          700: "hsl(var(--slate-700))",
          800: "hsl(var(--slate-800))",
          900: "hsl(var(--slate-900))",
        },
        primary: {
          DEFAULT: "hsl(var(--primary))",
          foreground: "hsl(var(--primary-foreground))",
          50: "hsl(var(--primary-50))",
          100: "hsl(var(--primary-100))",
          200: "hsl(var(--primary-200))",
          300: "hsl(var(--primary-300))",
          400: "hsl(var(--primary-400))",
          500: "hsl(var(--primary-500))",
          600: "hsl(var(--primary-600))",
          700: "hsl(var(--primary-700))",
          800: "hsl(var(--primary-800))",
          900: "hsl(var(--primary-900))",
          950: "hsl(var(--primary-900))",
        },
        secondary: {
          DEFAULT: "hsl(var(--secondary))",
          foreground: "hsl(var(--secondary-foreground))",
        },
        destructive: {
          DEFAULT: "hsl(var(--destructive))",
          foreground: "hsl(var(--destructive-foreground))",
        },
        muted: {
          DEFAULT: "hsl(var(--muted))",
          foreground: "hsl(var(--muted-foreground))",
        },
        accent: {
          DEFAULT: "hsl(var(--accent))",
          foreground: "hsl(var(--accent-foreground))",
        },
        popover: {
          DEFAULT: "hsl(var(--popover))",
          foreground: "hsl(var(--popover-foreground))",
        },
        card: {
          DEFAULT: "hsl(var(--card))",
          foreground: "hsl(var(--card-foreground))",
        },
        warning: "hsl(var(--warning))",
        "warning-foreground": "hsl(var(--warning-foreground))",
      },
      borderRadius: {
        lg: `var(--radius)`,
        md: `calc(var(--radius) - 2px)`,
        sm: "calc(var(--radius) - 4px)",
      },
      fontFamily: {
        sans: ["var(--font-sans)", ...fontFamily.sans],
        // heading: ["var(--font-heading)", ...fontFamily.sans],
      },
      keyframes: {
        "accordion-down": {
          from: { height: "0px" },
          to: { height: "var(--radix-accordion-content-height)" },
        },
        "accordion-up": {
          from: { height: "var(--radix-accordion-content-height)" },
          to: { height: "0px" },
        },
        enterFromRight: {
          from: { opacity: "0", transform: 'translateX(200px)' },
          to: { opacity: "1", transform: 'translateX(0)' },
        },
        enterFromLeft: {
          from: { opacity: "0", transform: 'translateX(-200px)' },
          to: { opacity: "1", transform: 'translateX(0)' },
        },
        exitToRight: {
          from: { opacity: "1", transform: 'translateX(0)' },
          to: { opacity: "0", transform: 'translateX(200px)' },
        },
        exitToLeft: {
          from: { opacity: "1", transform: 'translateX(0)' },
          to: { opacity: "0", transform: 'translateX(-200px)' },
        },
        scaleIn: {
          from: { opacity: "0", transform: 'rotateX(-10deg) scale(0.9)' },
          to: { opacity: "1", transform: 'rotateX(0deg) scale(1)' },
        },
        scaleOut: {
          from: { opacity: "1", transform: 'rotateX(0deg) scale(1)' },
          to: { opacity: "0", transform: 'rotateX(-10deg) scale(0.95)' },
        },
        fadeIn: {
          from: { opacity: "0" },
          to: { opacity: "1" },
        },
        fadeOut: {
          from: { opacity: "1" },
          to: { opacity: "0" },
        },
        "puff-in-center": {
          "0%": { 
            filter: "blur(3px)",
            opacity: "0",
            transform: "scale(1.1)" },
          "100%": { 
            filter: "blur(0)",
            opacity: "1",
            transform: "scale(1)" },
        },
        "open-scale-up-fade": {
          "0%": {
            opacity: "0",
            transform: "scale(.98) translateY(5px)",
          },
          "100%": {
            opacity: "1",
            transform: "scale(1) translateY(0)",
          },
        },
        'infinite-scroll': {
          from: { transform: 'translateX(0)' },
          to: { transform: 'translateX(-100%)' },
        },
        'infinite-scroll-inverse': {
          from: { transform: 'translateX(-100%)' },
          to: { transform: 'translateX(0)' },
        },
        'gradient-border': {
          to: { '--border-angle': '360deg' },
         }
      },
      animation: {
        "accordion-down": "accordion-down 0.2s ease-out",
        "accordion-up": "accordion-up 0.2s ease-out",
        scaleIn: 'scaleIn 200ms ease',
        scaleOut: 'scaleOut 200ms ease',
        fadeIn: 'fadeIn 200ms ease',
        fadeOut: 'fadeOut 200ms ease',
        enterFromLeft: 'enterFromLeft 250ms ease',
        enterFromRight: 'enterFromRight 250ms ease',
        exitToLeft: 'exitToLeft 250ms ease',
        exitToRight: 'exitToRight 250ms ease',
        "gradient-border": "gradient-border 4s linear infinite",
        "open-scale-up-fade": "open-scale-up-fade 1.5s ease-in-out",
        "spin-slow": "spin 10s linear infinite",
        'infinite-scroll': 'infinite-scroll 60s linear infinite',
        'infinite-scroll-inverse': 'infinite-scroll-inverse 60s linear infinite',
      },
    },
  },
  plugins: [require("tailwindcss-animate"), require("@tailwindcss/typography")],
} satisfies Config;
