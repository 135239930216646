"use client";

import { useTranslations } from "next-intl";
// import toast from "react-hot-toast";

import { Button } from "@/components/ui/button";

/** used as placeholder when env variables are not specified */
export function ButtonPlaceholder({ text, onClick }: { text: string, onClick: () => void }) {
  // const t = useTranslations("environments");
  // const notify = () => toast.error(t("auth-button-placeholder"));
  return (
    <>
      <Button variant="default" 
      onClick={onClick} 
      className="text-sm">
        {text}
      </Button>
    </>
  );
}
