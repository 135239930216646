"use client";

import "/node_modules/flag-icons/css/flag-icons.min.css";
import { settings, siteConfig } from "@/app";
import { env } from "@/env.mjs";
import { tv, type VariantProps } from "tailwind-variants";
import { cn } from "@/lib/utils"
import { useScroll } from "@/hooks";
import { Suspense } from 'react'
// import { cva, type VariantProps } from "class-variance-authority"


import { useTranslations } from "next-intl";
import { Combobox } from "@/components/navigation/combobox";
import { MainMenu } from "@/components/navigation/main-menu";
import { MobileMenu } from "@/components/navigation/mobile-menu";
import LocaleSwitcher from "@/components/switchers/localization-main-switcher";
import { ThemesGeneralSwitcher } from "@/components/switchers/themes-general-switcher";

import { ButtonPlaceholder } from "../placeholders";
import UserMenu from "./user-menu";

const NavbarStyles = tv({
  // base: "w-full border-b border-transparent bg-background/95",
  base: "w-full border-b border-transparent",
  variants: {
    border: {
      true: "border-border",
    },
    blur: {
      true: "backdrop-blur-md  bg-background/75",
    },
    sticky: {
      true: "sticky top-0 z-40",
    },
    animated: {
      true: "animate-in fade-in slide-in-from-top-full duration-slow",
    },
  },
});

export type SiteHeaderProps = {
  border?: boolean;
  sticky?: boolean;
  blur?: boolean;
} & VariantProps<typeof NavbarStyles>;

export function SiteHeader({
  border = true,
  sticky = true,
  blur = true,
}: SiteHeaderProps) {

  const scrolled = useScroll(80);
  const t = useTranslations('header');

  // border = scrolled ? true : false;
  // blur = scrolled ? true : false;
  blur = true;
  border = true;
  return (
    <header className={NavbarStyles({ border, sticky, blur })}>
      <nav className="container flex justify-between h-16 items-center">
        <MainMenu items={siteConfig.mainNav} />
        <MobileMenu
          MainMenuItems={siteConfig.mainNav}
          sidebarNavItems={siteConfig.mainNav}
        />
        <div className="flex flex-1 items-center justify-end space-x-2">
          <Combobox />
          {settings.themeToggleEnabled && <ThemesGeneralSwitcher />}
     
          {settings.internationalizationEnabled && <Suspense><LocaleSwitcher /></Suspense>}
          {/* open /blog/get-started on the same page */}
          <ButtonPlaceholder text={t('download')} onClick={() => window.open('/blog/get-started', '_self')} />
        </div>
      </nav>
    </header>
  );
}
