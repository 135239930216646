"use client";

import * as React from "react";
import { siteConfig } from "@/app";
import { Link } from "@/navigation";
import { type MainMenuItem } from "@/types";
import { cn } from "@/lib/utils";
import { ActivitySquare, Icon } from "lucide-react";
import { TheosIcon } from "@/components/icons";
import { Icons } from "@/components/icons";
import { Sun } from "lucide-react";
import { useTranslations } from "next-intl";


import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
  NavigationMenuIndicator,
  NavigationMenuViewport,
} from "@/components/ui/navigation-menu";

interface MainMenuProps {
  items?: MainMenuItem[];
}

export function MainMenu({ items }: MainMenuProps) {
  const t = useTranslations('siteConfig');

  return (
    <div className="hidden gap-6 lg:flex">
      <Link
        aria-label="Home"
        href="/"
        className="hidden items-center space-x-2 lg:flex"
      >
        {/* <ActivitySquare classåName="h-6 w-6" />
        <span className="hidden font-bold lg:inline-block font-heading">
          {siteConfig.name}
        </span> */}
        <TheosIcon className="size-14" />
      </Link>
      <NavigationMenu>
        <NavigationMenuList>
          {items?.[0]?.items ? (
            <NavigationMenuItem>
              <NavigationMenuTrigger className="h-auto">
                {t(items[0].title)}
              </NavigationMenuTrigger>
              <NavigationMenuContent>
                <ul className="grid gap-3 p-6 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
                  <li className="row-span-3">
                    <NavigationMenuLink asChild>
                      <Link
                        aria-label="Home"
                        className="flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none focus:shadow-md"
                        //href={siteConfig.company.link}
                        href="#main-video"
                      >
                        <div className="mb-2 mt-4 text-lg font-medium">
                          {siteConfig.name}
                        </div>
                        <p className="text-sm leading-tight text-muted-foreground">
                          {siteConfig.company.name}
                        </p>
                      </Link>
                    </NavigationMenuLink>
                  </li>
                  {items[0].items.map((item) => (
                    <ListItem
                      key={item.title}
                      title={t(item.title)}
                      href={item.href}
                    >
                      {t(item.description)}
                    </ListItem>
                  ))}
                </ul>
              </NavigationMenuContent>
            </NavigationMenuItem>
          ) : null}

          {items
            ?.filter((item) => item.title !== items[0]?.title)
            .map((item) =>
              item?.items ? (
                <NavigationMenuItem key={item.title}>
                  <NavigationMenuTrigger className="h-auto capitalize">
                    {t(item.title)}
                  </NavigationMenuTrigger>
                  <NavigationMenuContent>
                    {/* {item.icon && Icons[item.icon] && Icons[item.icon]} */}

                    {/* {item.items.map((item) => {

                      return <div className="p-3 ">
                        <div className="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors text-sm font-medium text-muted-foreground"
                        >
                          {item.title}
                        </div>
                        <ul className="grid w-[400px] gap-3 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
                          {item.items.map((item) => (
                            <ListItem
                              key={item.title}
                              title={item.title}
                              href={item.href}
                            >


                              {item.description}
                            </ListItem>))}
                        </ul>
                      </div>

                    }
                    )} */}

                    <div className="grid w-[400px] gap-3 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
                      {item.items.map((item) => {
                        return <ul className="p-3 "  key={item.title}>
                          <div className="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors text-sm font-medium text-muted-foreground"
                          >
                            {t(item.title)}
                          </div>

                          {item.items.map((item) => (
                            <ListItem
                              key={item.title}
                              title={t(item.title)}
                              href={item.href}
                              icon={item.icon}
                            >


                              {t(item.description)}
                            </ListItem>))}

                        </ul>
                      }
                      )}
                    </div>

                  </NavigationMenuContent>
                </NavigationMenuItem>
              ) : (
                item.href && (
                  <NavigationMenuItem key={item.title}>
                    <Link href={item.href} legacyBehavior passHref>
                      <NavigationMenuLink
                        className={cn(
                          navigationMenuTriggerStyle(),
                          "font-heading h-auto"
                        )}
                      >
                        {t(item.title)}
                      </NavigationMenuLink>
                    </Link>
                  </NavigationMenuItem>
                )
              )
            )}
          <NavigationMenuIndicator />
        </NavigationMenuList>

        <div className="perspective-[2000px] absolute top-full left-0 flex w-full justify-center">
          <NavigationMenuViewport />
        </div>
      </NavigationMenu>
    </div>
  );
}

interface LinkProps extends React.ComponentPropsWithoutRef<"a"> {
  icon?: keyof typeof Icons
}


const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  LinkProps
>(({ className, title, children, href, icon, ...props }, ref) => {

  //{<Icons[icon] className="h-6 w-6" />}

  const Icon = icon ? Icons[icon] : null;


  return (
    <li>
      <NavigationMenuLink asChild>
        <Link
          ref={ref}
          href={String(href)}
          className={cn(
            "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground group",
            className
          )}
          {...props}
        >

          {
            icon ? <div className={`flex justify-start items-center ${children ? "space-x-2 " : "space-x-1 "}`}>
              {/* {Icon && <Icon className="h-5 w-5" />} */}
              {Icon && 
              (children ? <div className="flex justify-center items-center border border-border p-2 rounded-md group-hover:bg-foreground group-hover:text-contrast">
              <Icon className="h-5 w-5"/>
              </div>
              :
              <Icon className="h-5 w-5" />)
              }
              <div className="flex flex-col space-y-1 justify-center">
                <div className="text-sm font-medium leading-none">{title}</div>
                {children && <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
                  {children}
                </p>}
              </div>
            </div> : <>
              <div className="text-sm font-medium leading-none">{title}</div>
              {children && <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
                {children}
              </p>}
            </>
          }

        </Link>
      </NavigationMenuLink>
    </li>
  );
});
ListItem.displayName = "ListItem";
