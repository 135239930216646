import type { ContentSection, MainMenuItem, FooterItem } from "./types";
import { networks } from "./server/config/social";
import { Sun } from "lucide-react";

export const appts = {
  name: "Theos AI",
  debug: false,
  social: networks({
    // youtube: "@theos-ai",
    // discord: "DqZ2e86r",
    // facebook: "profile.php?id=100076302661951",
    twitter: "theos_ai",
    // github: "theos-ai",
  }),
};

export default appts;

const links = {
  instagram: "https://instagram.com/ramacastro",
  twitter: "https://x.com/theos_ai",
  github: "https://github.com/blefnk/relivator",
  githubAccount: "https://github.com/theos-ai",
  discord: "https://discord.gg/DqZ2e86r",
  tiktok: "https://tiktok.com/@ramacastrom",
  facebook: "https://facebook.com/profile.php?id=100076302661951",
};

export const contactConfig = {
  email: "rama@theos.ai",
};

export const REPOSITORY_OWNER = "theos-ai";
export const REPOSITORY_NAME = "theos-landing";
export const REPOSITORY_URL = `https://github.com/${REPOSITORY_OWNER}/${REPOSITORY_NAME}`;
export const DISCORD_URL = "https://discord.gg/Pb8uKbwpsJ";
export const baseUrl = "https://theoscopilot.com";

export const BASE_URL =
  process.env.NODE_ENV === "production" ? baseUrl : "http://localhost:3000";
export const BRAND_NAME = "Theos AI";
export const BRAND_DESCRIPTION =
  "Next.js 14 free store and dashboard template. It helps you build great eCommerce and SaaS apps faster than ever. Get it now!";

// export const OWNER_ROLE = "owner";
// export const ADMIN_ROLE = "admin";
// export const MEMBER_ROLE = "member";

// export const ROLES = [OWNER_ROLE, ADMIN_ROLE, MEMBER_ROLE] as const;

export const settings = {
  internationalizationEnabled: true,
  themeToggleEnabled: false,
};

export const siteConfig = {
  name: "Theos Sales Copilot",
  shortName: "Theos AI",
  author: "Theos Artificial Intelligence, Inc.",
  description: "Send hundreds of invitation, chat and follow-up messages per day with human-like AI and book 10x more sales calls, close 10x more deals, 10x faster",
  company: {
    name: "Theos Artificial Intelligence, Inc.",
    link: "https://theoscopilot.com",
    email: "contact@theos.ai",
    twitter: "@theos_ai",
    address: "9450 Southwest Gemini Drive,\nBeaverton, OR 97008 United States.",
    phoneNumber: "+1 (737) 204-6474",
  },
  handles: {
    twitter: "@theos_ai",
  },
  keywords: [
    "LinkedIn",
    "Sales",
    "AI",
    "Artificial Intelligence",
    "Theos",
    "Theos AI",
    "Theos Sales Copilot",
    "Sales Copilot"
  ],
  url: {
    base: baseUrl,
    author: REPOSITORY_OWNER,
  },
  ogImage: `${baseUrl}/og-image.png`,
  mainNav: [
    {
      title: "Product",
      items: [
        {
          title: "Problem",
          href: "/#problems",
          description: "The problems we solve.",
          items: [],
        },
        {
          title: "Solution",
          href: "/#solution",
          description: "Linkedin and Instagram outreach.",
          label: "New",

          // items: [],
          items: [
          ]
        },
        {
          title: "Pricing",
          href: "/pricing",
          description: "Compare features and pricing.",
          items: [],
        },
      ],
    },



    // {
    //   title: "Products",
    //   items: [
    //     {
    //       title: "Sales Copilot",
    //       items: [
    //         {
    //           title: "Copilot overview",
    //           href: "/products",
    //           description: "All the products we have to offer.",
    //           items: [],
    //         },
    //         {
    //           title: "Build a Look",
    //           href: "/custom/clothing",
    //           description: "Build your own custom clothes.",
    //           items: [],
    //         },
    //         {
    //           title: "Blog",
    //           href: "/blog",
    //           description: "Read our latest blog posts.",
    //           items: [],
    //         },
    //       ],
    //     },
    //     {
    //       title: "API",
    //       items: [
    //         {
    //           title: "Platform overview",
    //           href: "/products",
    //           description: "We have to offer.",
    //           //icon: "sun",
    //           icon: "sun",
    //           items: [],
    //         },
    //         {
    //           title: "Build a Look",
    //           href: "/custom/clothing",
    //           description: "Build your own custom clothes.",
    //           items: [],
    //         },
    //         {
    //           title: "Blog",
    //           href: "/blog",
    //           description: "Read our latest blog posts.",
    //           items: [],
    //         },
    //       ],
    //     },
    //   ],
    // },

    {
      title: "Resources",
      items: [
        {
          title: "Get Started",
          items: [
            {
              title: "Read our guide",
              href: "/blog/get-started",
              //icon: "sun",
          
              items: [],
            },
            // {
            //   title: "Watch the demo",
            //   href: "/#demo",
            //   //icon: "sun",
          
            //   items: [],
            // },
            {
              title: "Blog",
              href: "/blog",
              //icon: "sun",
          
              items: [],
            },
            // {
            //   title: "Documentation",
            //   href: "/products",        
            //   items: [],
            // },
            // {
            //   title: "Blogs",
            //   href: "/custom/clothing",
            //   items: [],
            // },
            // {
            //   title: "Youtube",
            //   href: "/blog",
            //   items: [],
            // },
            // {
            //   title: "Community Chat",
            //   href: "/blog", 
            //   items: [],
            // },
          ],
        },
        {
          title: "Company",
          items: [
    
            {
              title: "Contact us",
              href: "/contact",
              items: [],
            },
          ],
        },
      ],
    },
  ] satisfies MainMenuItem[],
  links,
  footerNav: [
    {
      title: "Product",
      items: [
        {
          title: "Problem",
          href: "/#problems",
          external: false,
        },
        {
          title: "Solution",
          href: "/#solution",
          external: false,
        },
        {
          title: "Pricing",
          href: "/pricing",
          external: false,
        }
      ],
    },
    {
      title: "Help",
      items: [
        {
          title: "Contact",
          href: "/contact",
          external: false,
        },
        {
          title: "Privacy",
          href: "/privacy",
          external: false,
        },
        {
          title: "Terms",
          href: "/terms",
          external: false,
        },
        // {
        //   title: "About",
        //   href: "/about",
        //   external: false,
        // },
      ],
    },
    {
      title: "Social",
      items: [
        {
          title: "Instagram",
          href: links.instagram,
          external: true,
        },
        {
          title: "Tiktok",
          href: links.tiktok,
          external: true,
        },
        {
          title: "X",
          href: links.twitter,
          external: true,
        }
      ],
    },
    // {
    //   title: "Github",
    //   items: [
    //     {
    //       title: "Nomaders",
    //       href: "https://github.com/blefnk/nomaders",
    //       external: true,
    //     },
    //     {
    //       title: "Reliverse",
    //       href: "https://github.com/blefnk/reliverse",
    //       external: true,
    //     },
    //     {
    //       title: "Relivator",
    //       href: "https://github.com/blefnk/relivator",
    //       external: true,
    //     },
    //     {
    //       title: "Utilities",
    //       href: "https://github.com/blefnk/utils",
    //       external: true,
    //     },
    //   ],
    // },
  ] satisfies FooterItem[],
};

export const featureCards: ContentSection = {
  header: `Powered by`,
  subheader: `What Makes Relivator Possible`,
  content: [
    {
      text: `Next.js`,
      subtext: `The React Framework`,
    },
    {
      text: `shadcn/ui`,
      subtext: `Beautifully Designed Components`,
    },
    {
      text: `Vercel`,
      subtext: `Develop. Preview. Ship.`,
    },
  ],
};

export const features: ContentSection = {
  header: `Features`,
  subheader: `Why You Need to Download Relivator`,
  content: [
    {
      text: `SEO Optimized`,
      subtext: `Improved website visibility on search engines`,
    },
    {
      text: `Highly Performant`,
      subtext: `Fast loading times and smooth performance`,
    },
    {
      text: `Easy Customization`,
      subtext: `Change your content and layout with little effort`,
    },
  ],
};
